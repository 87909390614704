/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@include mat.core();

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.m2-define-light-theme(
  (
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($theme);
@include mat.dialog-theme($theme);
@include mat.autocomplete-theme($theme);
