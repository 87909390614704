@import 'variables/_variables.scss';

/*******
* Colors
*******/

@layer base {
  :root {
    --color-button-flat-background: var(--color-blue-500);
    --color-marking-new: var(--color-bordeaux-510);
    --color-text-bordeaux: var(--color-bordeaux-500);
    --color-background-page: var(--color-gray-100);
    --color-background-dark: var(--color-gray-200);
    --color-divider: var(--color-gray-330);
    --color-text-default: var(--color-gray-500);
    --color-button-outline-border: var(--color-blue-500);
    --color-button-flat-background-hover: var(--color-blue-600);
    --color-button-flat-background-pressed: var(--color-blue-700);
    --color-button-outline-background-hover: var(--color-blue-515);
    --color-button-outline-background-pressed: var(--color-blue-520);
    --color-button-flat-background-disabled: var(--color-blue-550);
    --color-button-outline-border-disabled: var(--color-blue-550);
    --color-button-flat-foreground: var(--color-white);
    --color-button-outline-foreground: var(--color-blue-500);
    --color-button-flat-foreground-disabled: var(--color-white-50);
    --color-button-outline-foreground-disabled: var(--color-blue-500);
    --color-button-outline-border-pressed: var(--color-blue-700);
    --color-icon-button-secondary-bordeaux-background: var(--color-bordeaux-500);
    --color-icon-button-secondary-bordeaux-background-hover: var(--color-bordeaux-600);
    --color-icon-button-secondary-bordeaux-background-pressed: var(--color-bordeaux-700);
    --color-icon-button-secondary-bordeaux-background-active: var(--color-bordeaux-500);
    --color-icon-button-secondary-bordeaux-background-disabled: var(--color-bordeaux-500);
    --color-icon-button-tertiary-white-background: var(--color-gray-200);
    --color-icon-button-tertiary-white-background-hover: var(--color-blue-515);
    --color-icon-button-tertiary-white-background-pressed: var(--color-blue-530);
    --color-icon-button-tertiary-white-background-active: var(--color-blue-515);
    --color-icon-button-tertiary-white-background-disabled: var(--color-gray-200);
    --color-icon-button-tertiary-white-foreground: var(--color-gray-300);
    --color-icon-button-tertiary-white-foreground-active: var(--color-blue-500);
    --color-icon-button-tertiary-white-foreground-disabled: var(--color-gray-350);
    --color-button-ghost-foreground: var(--color-blue-500);
    --color-button-ghost-foreground-hover: var(--color-blue-700);
    --color-button-ghost-foreground-disabled: var(--color-blue-550);
    --color-actions: var(--color-blue-500);
    --color-marking-highlight: var(--color-bordeaux-500);
    --color-widget-background: var(--color-white);
    --color-banner-icon-background-success: var(--color-green-500);
    --color-banner-icon-background-error: var(--color-red-500);
    --color-banner-icon-background-warning: var(--color-orange-500);
    --color-banner-icon-background-info: var(--color-blue-500);
    --color-banner-icon-foreground: var(--color-white);
    --color-banner-background: var(--color-white);
    --color-banner-divider: var(--color-gray-330);
    --color-label-background-orange: var(--color-orange-520);
    --color-label-foreground-orange: var(--color-orange-700);
    --color-label-background-red: var(--color-red-520);
    --color-label-foreground-red: var(--color-red-600);
    --color-label-background-green: var(--color-green-520);
    --color-label-foreground-green: var(--color-green-700);
    --color-label-background-default: var(--color-gray-315);
    --color-label-foreground-default: var(--color-gray-500);
    --color-background-white: var(--color-white);
    --color-text-gray: var(--color-gray-300);
    --color-icon-actions: var(--color-gray-300);
    --color-icon-info: var(--color-gray-500);
    --color-icon-navigation: var(--color-gray-400);
    --color-input-helper: var(--color-gray-375);
    --color-input-label: var(--color-text-gray);
    --color-input-text: var(--color-text-default);
    --color-input-outline: var(--color-divider);
    --color-input-background: var(--color-white);
    --color-input-outline-hover: var(--color-blue-550);
    --color-input-outline-focused: var(--color-blue-500);
    --color-input-outline-warning: var(--color-marking-warning);
    --color-marking-warning: var(--color-orange-500);
    --color-marking-error: var(--color-red-500);
    --color-input-outline-error: var(--color-marking-error);
    --color-input-text-disabled: var(--color-gray-350);
    --color-input-helper-disabled: var(--color-gray-330);
    --color-input-outline-disabled: var(--color-gray-315);
    --color-overlays-background: var(--color-white);
    --color-overlays-background-gray: var(--color-gray-100);
    --color-framework-footer-background: var(--color-gray-700);
    --color-framework-footer-foreground: var(--color-gray-400);
    --color-framework-navigation-background-active: var(--color-gray-330);
    --color-framework-navigation-foreground: var(--color-gray-400);
    --color-framework-navigation-foreground-active: var(--color-white);
    --color-framework-navigation-highlight: var(--color-bordeaux-500);
    --color-framework-navigation-divider: var(--color-gray-900);
    --color-framework-navigation-background: var(--color-gray-600);
    --color-framework-metanavi-background: var(--color-white);
    --color-framework-metanavi-text-bordeaux: var(--color-text-bordeaux);
    --color-framework-metanavi-icons: var(--color-gray-350);
    --color-framework-metanavi-text: var(--color-text-default);
    --color-framework-metanavi-divider: var(--color-divider);
    --color-faq-kachel-background: var(--color-background-white);
    --color-widget-text: var(--color-text-default);
    --color-widget-line: var(--color-divider);
    --color-widget-icon: var(--color-gray-375);
    --color-widget-text-gray: var(--color-text-gray);
    --color-table-background-header: var(--color-gray-200);
    --color-table-text-line: var(--color-text-default);
    --color-table-text-line-gray: var(--color-gray-375);
    --color-table-dividers-line: var(--color-divider);
    --color-pagination-background: var(--color-gray-100);
    --color-pagination-text: var(--color-text-default);
    --color-pagination-icon: var(--color-icon-actions);
    --color-pagination-icon-disabled: var(--color-gray-330);
    --color-text-white: var(--color-white);
    --color-faq-kachel-text: var(--color-text-default);
    --color-faq-kachel-icon: var(--color-text-default);
    --color-faq-kachel-icon-lev2: var(--color-text-default);
    --color-faq-kachel-icon-background: var(--color-background-page);
    --color-faq-search-highlight: var(--color-green-520);
    --color-faq-kachel-text-gray: var(--color-text-gray);
    --color-contextmenu-background: var(--color-background-white);
    --color-contextmenu-text: var(--color-text-default);
    --color-contextmenu-outline: var(--color-gray-315);
    --color-contextmenu-dividers: var(--color-divider);
    --color-news-text: var(--color-text-default);
    --color-news-text-gray: var(--color-text-gray);
    --color-pagination-slider-highlight: var(--color-marking-highlight);
    --color-pagination-slider-gray: var(--color-gray-350);
    --color-widget-promo-background: var(--color-gray-200);
    --color-news-image-background: var(--color-background-page);
    --color-icon-gray: var(--color-gray-375);
    --color-news-background: var(--color-gray-100);
    --color-tab-background: var(--color-white);
    --color-tab-foreground-default: var(--color-gray-375);
    --color-tab-foreground-hover: var(--color-gray-500);
    --color-tab-foreground-active: var(--color-gray-500);
    --color-tab-foreground-disabled: var(--color-gray-350);
    --color-link-foreground-default: var(--color-blue-500);
    --color-link-foreground-hover: var(--color-blue-500);
    --color-link-foreground-active: var(--color-blue-500);
    --color-link-foreground-disabled: var(--color-gray-350);
    --color-framework-metanavi-background-mobile: var(--color-gray-700);
    --color-framework-metanavi-logo-mobile: var(--color-bordeaux-400);
    --color-overlays-tint: var(--color-gray-944);
    --color-info-message-background: var(--color-gray-315);
    --color-info-message-foreground: var(--color-text-gray);
    --color-input-label-disabled: var(--color-input-text-disabled);
    --color-framework-navigation-foreground-hover: var(--color-gray-400);
    --color-framework-navigation-background-hover: var(--color-gray-330);
    --color-interactive-focusvisible: var(--color-blue-400);
    --color-shadow-interactive: var(--color-gray-306);
    --color-shadow-dropdown: var(--color-gray-320);
    --color-shadow-floating: var(--color-gray-340);
    --color-label-background-blue: var(--color-blue-520);
    --color-label-foreground-blue: var(--color-blue-700);
    --color-label-background-bordeaux: var(--color-bordeaux-320);
    --color-label-foreground-bordeaux: var(--color-bordeaux-500);
    --color-interactive-hover: var(--color-gray-905);
    --color-label-background-new: var(--color-bordeaux-500);
    --color-label-foreground-new: var(--color-white);
    --color-shadow-sticky-table-column: var(--color-gray-100);
    --color-interactive-focus: var(--color-gray-320);
    --color-icon-thumbnail: var(--color-gray-500);
  }

  .dark {
    --color-button-flat-background: var(--color-blue-500);
    --color-marking-new: var(--color-bordeaux-530);
    --color-text-bordeaux: var(--color-bordeaux-400);
    --color-background-page: var(--color-gray-900);
    --color-background-dark: var(--color-gray-1000);
    --color-divider: var(--color-gray-420);
    --color-text-default: var(--color-white);
    --color-button-outline-border: var(--color-blue-500);
    --color-button-flat-background-hover: var(--color-blue-600);
    --color-button-flat-background-pressed: var(--color-blue-700);
    --color-button-outline-background-hover: var(--color-blue-515);
    --color-button-outline-background-pressed: var(--color-blue-520);
    --color-button-flat-background-disabled: var(--color-blue-550);
    --color-button-outline-border-disabled: var(--color-blue-550);
    --color-button-flat-foreground: var(--color-white);
    --color-button-outline-foreground: var(--color-blue-500);
    --color-button-flat-foreground-disabled: var(--color-white);
    --color-button-outline-foreground-disabled: var(--color-blue-500);
    --color-button-outline-border-pressed: var(--color-blue-700);
    --color-icon-button-secondary-bordeaux-background: var(--color-bordeaux-500);
    --color-icon-button-secondary-bordeaux-background-hover: var(--color-bordeaux-600);
    --color-icon-button-secondary-bordeaux-background-pressed: var(--color-bordeaux-500);
    --color-icon-button-secondary-bordeaux-background-active: var(--color-bordeaux-500);
    --color-icon-button-secondary-bordeaux-background-disabled: var(--color-bordeaux-500);
    --color-icon-button-tertiary-white-background: var(--color-gray-200);
    --color-icon-button-tertiary-white-background-hover: var(--color-blue-515);
    --color-icon-button-tertiary-white-background-pressed: var(--color-blue-530);
    --color-icon-button-tertiary-white-background-active: var(--color-blue-515);
    --color-icon-button-tertiary-white-background-disabled: var(--color-gray-200);
    --color-icon-button-tertiary-white-foreground: var(--color-gray-300);
    --color-icon-button-tertiary-white-foreground-active: var(--color-blue-500);
    --color-icon-button-tertiary-white-foreground-disabled: var(--color-gray-350);
    --color-button-ghost-foreground: var(--color-blue-500);
    --color-button-ghost-foreground-hover: var(--color-blue-700);
    --color-button-ghost-foreground-disabled: var(--color-blue-550);
    --color-actions: var(--color-blue-500);
    --color-marking-highlight: var(--color-bordeaux-500);
    --color-widget-background: var(--color-gray-700);
    --color-banner-icon-background-success: var(--color-green-500);
    --color-banner-icon-background-error: var(--color-red-500);
    --color-banner-icon-background-warning: var(--color-orange-500);
    --color-banner-icon-background-info: var(--color-blue-500);
    --color-banner-icon-foreground: var(--color-white);
    --color-banner-background: var(--color-gray-600);
    --color-banner-divider: var(--color-gray-500);
    --color-label-background-orange: var(--color-orange-520);
    --color-label-foreground-orange: var(--color-orange-500);
    --color-label-background-red: var(--color-red-520);
    --color-label-foreground-red: var(--color-red-500);
    --color-label-background-green: var(--color-green-520);
    --color-label-foreground-green: var(--color-green-500);
    --color-label-background-default: var(--color-gray-320);
    --color-label-foreground-default: var(--color-text-white);
    --color-background-white: var(--color-gray-700);
    --color-text-gray: var(--color-gray-400);
    --color-icon-actions: var(--color-gray-300);
    --color-icon-info: var(--color-gray-500);
    --color-icon-navigation: var(--color-gray-400);
    --color-input-helper: var(--color-gray-375);
    --color-input-label: var(--color-text-gray);
    --color-input-text: var(--color-text-default);
    --color-input-outline: var(--color-divider);
    --color-input-background: var(--color-gray-700);
    --color-input-outline-hover: var(--color-blue-550);
    --color-input-outline-focused: var(--color-blue-500);
    --color-input-outline-warning: var(--color-marking-warning);
    --color-marking-warning: var(--color-orange-500);
    --color-marking-error: var(--color-red-500);
    --color-input-outline-error: var(--color-marking-error);
    --color-input-text-disabled: var(--color-gray-350);
    --color-input-helper-disabled: var(--color-gray-330);
    --color-input-outline-disabled: var(--color-gray-315);
    --color-overlays-background: var(--color-gray-700);
    --color-overlays-background-gray: var(--color-gray-600);
    --color-framework-footer-background: var(--color-gray-700);
    --color-framework-footer-foreground: var(--color-gray-400);
    --color-framework-navigation-background-active: var(--color-gray-330);
    --color-framework-navigation-foreground: var(--color-gray-400);
    --color-framework-navigation-foreground-active: var(--color-white);
    --color-framework-navigation-highlight: var(--color-bordeaux-400);
    --color-framework-navigation-divider: var(--color-gray-900);
    --color-framework-navigation-background: var(--color-gray-600);
    --color-framework-metanavi-background: var(--color-gray-700);
    --color-framework-metanavi-text-bordeaux: var(--color-text-bordeaux);
    --color-framework-metanavi-icons: var(--color-gray-450);
    --color-framework-metanavi-text: var(--color-text-default);
    --color-framework-metanavi-divider: var(--color-divider);
    --color-faq-kachel-background: var(--color-background-white);
    --color-widget-text: var(--color-text-default);
    --color-widget-line: var(--color-divider);
    --color-widget-icon: var(--color-gray-475);
    --color-widget-text-gray: var(--color-text-gray);
    --color-table-background-header: var(--color-gray-600);
    --color-table-text-line: var(--color-text-default);
    --color-table-text-line-gray: var(--color-gray-475);
    --color-table-dividers-line: var(--color-divider);
    --color-pagination-background: var(--color-gray-420);
    --color-pagination-text: var(--color-text-default);
    --color-pagination-icon: var(--color-gray-100);
    --color-pagination-icon-disabled: var(--color-gray-330);
    --color-text-white: var(--color-white);
    --color-faq-kachel-text: var(--color-text-default);
    --color-faq-kachel-icon: var(--color-text-default);
    --color-faq-kachel-icon-lev2: var(--color-text-default);
    --color-faq-kachel-icon-background: var(--color-gray-420);
    --color-faq-search-highlight: var(--color-green-520);
    --color-faq-kachel-text-gray: var(--color-text-gray);
    --color-contextmenu-background: var(--color-background-white);
    --color-contextmenu-text: var(--color-text-default);
    --color-contextmenu-outline: var(--color-gray-315);
    --color-contextmenu-dividers: var(--color-table-dividers-line);
    --color-news-text: var(--color-text-default);
    --color-news-text-gray: var(--color-text-gray);
    --color-pagination-slider-highlight: var(--color-marking-highlight);
    --color-pagination-slider-gray: var(--color-gray-450);
    --color-widget-promo-background: var(--color-gray-600);
    --color-news-image-background: var(--color-gray-200);
    --color-icon-gray: var(--color-gray-475);
    --color-news-background: var(--color-gray-500);
    --color-tab-background: var(--color-gray-700);
    --color-tab-foreground-default: var(--color-gray-375);
    --color-tab-foreground-hover: var(--color-white);
    --color-tab-foreground-active: var(--color-white);
    --color-tab-foreground-disabled: var(--color-gray-350);
    --color-link-foreground-default: var(--color-blue-500);
    --color-link-foreground-hover: var(--color-blue-500);
    --color-link-foreground-active: var(--color-blue-500);
    --color-link-foreground-disabled: var(--color-gray-350);
    --color-framework-metanavi-background-mobile: var(--color-gray-700);
    --color-framework-metanavi-logo-mobile: var(--color-bordeaux-400);
    --color-overlays-tint: var(--color-black-50);
    --color-info-message-background: var(--color-gray-420);
    --color-info-message-foreground: var(--color-text-gray);
    --color-input-label-disabled: var(--color-input-text-disabled);
    --color-framework-navigation-foreground-hover: var(--color-gray-400);
    --color-framework-navigation-background-hover: var(--color-gray-330);
    --color-interactive-focusvisible: var(--color-blue-400);
    --color-shadow-interactive: var(--color-black-08);
    --color-shadow-dropdown: var(--color-black-40);
    --color-shadow-floating: var(--color-black-40);
    --color-label-background-blue: var(--color-blue-520);
    --color-label-foreground-blue: var(--color-blue-500);
    --color-label-background-bordeaux: var(--color-bordeaux-320);
    --color-label-foreground-bordeaux: var(--color-bordeaux-300);
    --color-interactive-hover: var(--color-white-05);
    --color-label-background-new: var(--color-bordeaux-400);
    --color-label-foreground-new: var(--color-white);
    --color-shadow-sticky-table-column: var(--color-gray-944);
    --color-interactive-focus: var(--color-gray-320);
    --color-icon-thumbnail: var(--color-white);
  }

  // other css variables

  :root {
    --mat-autocomplete-background-color: var(--color-input-background);
  }
}

/* BASE */
@import 'tailwindcss/base';
@import 'base/_base.scss';
@import 'base/_fonts.scss';
@import 'base/_reset.scss';
@import 'base/_typo.scss';

/* COMPONENTS */
@import 'tailwindcss/components';
@import 'components/_button.scss';
@import 'components/_print.scss';
@import 'components/_container.scss';
@import 'components/_datareporter.scss';
@import 'components/_editor-content.scss';
@import 'components/_form.scss';
@import 'components/_list.scss';
@import 'components/_link.scss';
@import 'components/_pseudo-shadow.scss';

/* UTILITIES */
@import 'tailwindcss/utilities';
