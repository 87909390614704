.editor-content {
  @apply typo-body;

  a {
    @apply link-primary;
  }

  p {
    @apply mb-3;
  }

  iframe {
    @apply max-w-full;
  }

  > *:first-child {
    @apply mt-0;
  }

  > *:last-child {
    @apply mb-0;
  }

  b,
  strong {
    @apply font-bold;
  }

  h1 {
    @apply typo-h0 mt-6 mb-3;
  }

  h2 {
    @apply typo-h1 mt-4 mb-3;
  }

  h3 {
    @apply typo-h2 mt-4 mb-2;
  }

  ol {
    @apply space-y-1 my-3 ml-4;
    li {
      @apply pl-2 list-decimal;
    }
  }

  ul {
    @apply space-y-1 my-3;
    li {
      @apply relative pl-6;
      &:after {
        @apply absolute size-5 left-0 top-0 mt-px;
        content: url('data:image/svg+xml,<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_778_1053)"><path d="m14.683 5.507-6.85 6.85L5.317 9.84a.312.312 0 0 0-.442 0l-.737.737a.313.313 0 0 0 0 .441l3.474 3.475a.313.313 0 0 0 .442 0l7.808-7.807a.313.313 0 0 0 0-.442l-.737-.737a.313.313 0 0 0-.442 0" fill="%23778187" fill-opacity="0.75" /></g></svg>');
      }
    }
  }
}
