#dr_blocking_div {
  @apply bg-overlays-tint #{!important};
}

#dr_cookie_banner_container {
  @apply max-w-5xl p-5 rounded shadow-floating #{!important};
}

#dr-tab-5-details {
  @apply bg-info-message-background p-4 rounded #{!important};
}

#cc-allow-01 {
  @apply rounded #{!important};
}

#dr_tab_5 {
  @apply rounded #{!important};
}

.dr-right.dr-title.dr-show-mobile.dr-roundcorners-right {
  @apply overflow-visible;
}

.dr-overview-item.dr-roundcorners.dr-btn-shadow.dr-key-tabable {
  @apply overflow-visible;
}

.cc-window .dr-overview-item span.dr-icon {
  @apply bg-button-flat-background #{!important};
}

.cc-revoke.cc-bottom {
  display: none;
}

@media screen and (max-width: 780px) {
  .cc-window.cc-middle {
    @apply w-auto mx-4 #{!important};
  }
}
