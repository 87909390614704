.lis-container {
  @apply container mx-auto px-4 md:px-8;
}

.lis-container--max.lis-container--max {
  @apply max-w-none;
}

.lis-container--l.lis-container--l {
  @apply max-w-screen-lg;
}
