.typo-h0 {
  @apply text-xl font-bold;
}

.typo-h1 {
  @apply text-lg font-normal;
}

.typo-h2 {
  @apply text-base font-normal;
}

.typo-h2-bold {
  @apply text-base font-bold;
}

.typo-s1 {
  @apply text-sm font-bold;
}

.typo-s2 {
  @apply text-xs font-bold;
}

.typo-s2-medium {
  @apply text-xs font-medium;
}

.typo-body {
  @apply text-sm font-normal;
}

.typo-body-s {
  @apply text-xs font-normal;
}

.typo-body-xs {
  @apply text-2xs font-medium tracking-wide;
}

.typo-button {
  @apply text-sm font-bold tracking-wide;
}

.typo-button-l {
  @apply text-base font-bold tracking-wide;
}

// special

.typo-header-logo {
  @apply text-base font-bold;
}

.typo-navigation-badge {
  @apply text-3xs font-bold tracking-wide;
}
