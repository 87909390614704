html {
  @apply scroll-smooth;
}

html,
body {
  @apply h-full;
}

body {
  @apply bg-background-page text-text-default;
}

svg {
  @apply w-full h-full fill-current;
}

img {
  @apply w-full max-w-full h-auto;
}

button {
  @apply tracking-normal;
}

b,
strong {
  @apply font-bold;
}
