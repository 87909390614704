.pseudo-shadow-left,
.pseudo-shadow-right {
  @apply relative;

  &:after {
    @apply absolute transform from-shadow-sticky-table-column;
    content: '';
  }
}

.pseudo-shadow-left {
  &:after {
    @apply inset-y-0 left-0 h-full w-2 -translate-x-2 bg-gradient-to-l;
  }
}

.pseudo-shadow-right {
  &:after {
    @apply inset-y-0 right-0 h-full w-2 translate-x-2 bg-gradient-to-r;
  }
}
