.form-input,
.form-select,
.form-textarea {
  @apply block w-full bg-input-background rounded px-3 appearance-none placeholder:text-text-gray text-left focus:outline-0 focus:outline-offset-0 focus:ring-0 focus:border-input-outline-focused font-normal text-sm text-input-text border-divider border;
  &:disabled {
    @apply text-input-text-disabled cursor-not-allowed border-input-outline-disabled;
  }

  &.form-readonly {
    @apply bg-transparent text-input-text rounded-none border-0 border-b border-input-outline px-0;
  }

  &.form-input-search {
    @apply px-10;
  }
}

.form-input,
.form-select {
  @apply h-[2.125rem] py-0;
}

.form-select {
  @apply pr-10 bg-none;
}

.form-textarea {
  @apply py-2.5;
}

input[type='date']::-webkit-date-and-time-value {
  @apply text-left;
}

.form-error:not(:focus) {
  @apply border-input-outline-warning focus:border-input-outline-warning;
}

select {
  @apply bg-none;
}

.form-radio {
  @apply bg-transparent text-input-outline-focused focus:bg-input-outline-focused border-2 size-5 focus:ring-transparent focus:ring-offset-transparent focus:outline-2 focus:outline-offset-2 focus:outline-interactive-focusvisible;
}

label {
  a {
    @apply underline;
  }
}

.form-focus-visible {
  @apply focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-interactive-focusvisible;
}
