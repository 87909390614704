.lis-print {
  @apply hidden;
}

@media print {
  body {
    @apply text-black bg-white #{!important};
  }
  .lis-app {
    @apply hidden #{!important};
  }

  .lis-print {
    @apply block;
  }

  .cdk-overlay-container {
    @apply hidden;
  }
}
