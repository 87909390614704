.lis-list {
  @apply space-y-1;

  li {
    @apply relative pl-4;

    &:before {
      @apply absolute top-1/2 left-0 w-2 h-px bg-black;
      content: '';
    }
  }
}

.lis-checked-list {
  @apply pl-5 space-y-5 list-image-[url('/assets/icons/check-16.svg')];

  li {
    @apply pl-1;
  }
}

.lis-dot-list {
  @apply space-y-1 my-3 pl-4;
  li {
    @apply relative pl-4 after:content-[''] after:absolute after:size-1 after:left-0 after:top-0 after:rounded after:bg-black after:mt-2.5;
  }
}
